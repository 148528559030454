import React, { useCallback, useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useUpdatePartnerAuthorizedUsers } from 'hooks/withPartner';
import { Locale } from '../../../types/partnerTypes';

const rules = [
  {
    required: true,
    validator: async (_, value) => {
      if (!value || value.length != 12 || !numberMatcher.test(value)) {
        return Promise.reject(
          new Error('Input a valid 12 number length personal number')
        );
      }
    },
  },
];

function arraysContainsSameElements(arrA: string[], arrB: string[]): boolean {
  if (arrA.length != arrB.length) {
    return false;
  } else {
    return arrA.every((s) => arrB.includes(s));
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 5 },
  },
};

type EditUsersModalProps = {
  closeForm: () => void;
  initialData?: string[] | undefined;
  orgId: string;
};

type FormData = {
  authorizedUsers: { id: string }[];
};

const numberMatcher = new RegExp(/^[0-9]+$/);

const EditUsersModal: React.FC<EditUsersModalProps> = ({
  closeForm,
  initialData,
  orgId,
}) => {
  const [authorizedUsers, setAuthorizedUsers] = useState<string[]>(
    initialData ? initialData : []
  );

  const [putUpdateAuthorizedUsers, isLoadingUpdateAuthorizedUsers] =
    useUpdatePartnerAuthorizedUsers();

  const handleUpdateAuthorizedUsers = useCallback(
    (formData: FormData) => {
      const formAuthorizedUsers: string[] = formData.authorizedUsers.map(
        (a) => a.id
      );

      if (!arraysContainsSameElements(formAuthorizedUsers, authorizedUsers)) {
        try {
          putUpdateAuthorizedUsers({
            orgId: orgId,
            authorizedUsers: formAuthorizedUsers,
            requesterLocale: Locale.sv_SE,
          });
          setAuthorizedUsers(formAuthorizedUsers);
        } catch (e) {
          alert('Error: ' + e);
        }
      }
    },
    [authorizedUsers, orgId, putUpdateAuthorizedUsers]
  );

  const initialValues: FormData = {
    authorizedUsers: initialData ? initialData.map((s) => ({ id: s })) : [],
  };

  return (
    <Form
      onFinish={handleUpdateAuthorizedUsers}
      className="my-form"
      initialValues={initialValues}
    >
      <Form.List name="authorizedUsers">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => {
              return (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? 'Authorized Users' : ''}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    name={[field.name, 'id']}
                    rules={rules}
                    noStyle
                  >
                    <Input
                      placeholder="a valid personal number"
                      style={{ width: '60%' }}
                    />
                  </Form.Item>
                  {fields.length > 0 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                Add authorized user id
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoadingUpdateAuthorizedUsers}
          >
            Submit
          </Button>
          <Button
            type="primary"
            htmlType="button"
            loading={isLoadingUpdateAuthorizedUsers}
            onClick={closeForm}
          >
            Close
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default EditUsersModal;
