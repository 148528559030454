import axios, { AxiosResponse } from 'axios';
import { ENDPOINT_BASE } from 'constants/index';

const AUTHENTICATION_PATH = 'authentication';
const STATUSES_PATH = 'statuses';

const baseUrl = `${ENDPOINT_BASE}${STATUSES_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

export type AuthenticationStatusResponse = {
  authenticated: boolean;
};

export async function authenticationStatus(): Promise<
  AxiosResponse<AuthenticationStatusResponse>
> {
  return client.get<AuthenticationStatusResponse>(`/${AUTHENTICATION_PATH}`);
}
