import axios, { AxiosResponse } from 'axios';
import { ENDPOINT_BASE } from 'constants/index';
import {
  CreatePartnerRequest,
  CreatePartnerResponse,
  GetPartnerInfoResponse,
  GetPartnerRequest,
  Locale,
  UpdatePartnerRequest,
} from '../types/partnerTypes';

const PARTNERS_PATH = 'partners';
const baseUrl = `${ENDPOINT_BASE}${PARTNERS_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

export async function getPartner(
  request: GetPartnerRequest | undefined | null
): Promise<AxiosResponse<GetPartnerInfoResponse>> {
  if (!request) {
    throw new Error('GetPartnersRequest is missing on getPartner api call');
  }
  return client.get<GetPartnerInfoResponse>(
    `/${request.orgId}/${request.requesterLocale}`
  );
}

export async function getPartners(
  requesterLocale: Locale | undefined | null
): Promise<AxiosResponse<Array<GetPartnerInfoResponse>>> {
  if (!requesterLocale) {
    throw new Error('requesterLocale is missing on getPartners api call');
  }
  return client.get<Array<GetPartnerInfoResponse>>(`/${requesterLocale}`);
}

export async function createPartner(
  request: CreatePartnerRequest | undefined | null
): Promise<AxiosResponse<CreatePartnerResponse>> {
  if (!request) {
    throw new Error(
      'CreatePartnerRequest is missing on createPartner api call'
    );
  }
  return client.post<CreatePartnerResponse>('', request);
}

export async function updatePartnerAuthorizedUsers(
  request: UpdatePartnerRequest | undefined | null
): Promise<AxiosResponse<GetPartnerInfoResponse>> {
  if (!request) {
    throw new Error(
      'UpdatePartnerRequest is missing on updatePartnerAuthorizedUsers api call'
    );
  }
  return client.put<GetPartnerInfoResponse>('', request);
}
