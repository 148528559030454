import React, { useCallback } from 'react';

import { Button, Form, Input } from 'antd';

import {
  useBankidAuth,
  useBankidCollect,
  useCancelAuthentication,
  isAuthenticating,
} from 'hooks/withBankId';

type FieldType = {
  pno?: string;
};

const LoginView: React.FC = () => {
  const [postBankid, isLoadingBankId, bankIdResponse, handleBankIdReset] =
    useBankidAuth();

  const [collectStatus, , collectData] = useBankidCollect(
    bankIdResponse?.orderRef,
    handleBankIdReset
  );

  const [mutateCancelBankIdAuth] = useCancelAuthentication();

  const handleLogin = useCallback(
    ({ pno }) => {
      postBankid({ pno, tokenStartRequired: false });
    },
    [postBankid]
  );

  const handleCancelBankIdAuth = useCallback(() => {
    mutateCancelBankIdAuth(bankIdResponse?.orderRef, {
      onSettled: handleBankIdReset,
    });
  }, [bankIdResponse?.orderRef, mutateCancelBankIdAuth, handleBankIdReset]);

  function ifIsAuthenticating() {
    return isAuthenticating(
      collectStatus,
      bankIdResponse,
      collectData,
      isLoadingBankId
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 w-full">
      <div
        style={{
          minWidth: '340px',
          maxWidth: '400px',
        }}
        className="px-8 py-6 mt-4 text-left bg-white shadow-lg w-2/5 flex align-center justify-center flex-col"
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleLogin}
          autoComplete="off"
        >
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <h3 className="text-xl font-bold">Login to your account</h3>
            <h4>Status: {collectData ? collectData : 'Waiting for input'}</h4>
          </Form.Item>
          <Form.Item<FieldType>
            label="Pno"
            name="pno"
            rules={[{ required: true, message: 'Please input your pno!' }]}
          >
            <Input placeholder="19901212999" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={ifIsAuthenticating()}
            >
              Authenticate using BankID
            </Button>
            {ifIsAuthenticating() && (
              <Button onClick={handleCancelBankIdAuth}>Cancel</Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginView;
