import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Space, Table } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { usePartner } from 'hooks/withPartner';
import { Locale } from '../../types/partnerTypes';
import {
  useAddRegisteredClient,
  useRemoveRegisteredClient,
  useUpdateRegisteredClient,
  useUpdateRegisteredClientSecret,
} from 'hooks/withRegisteredClients';

type IdParams = {
  id: string;
};
const RegisteredClientsFormView: React.FC = () => {
  const { id } = useParams<keyof IdParams>() as IdParams;

  const [
    addRegisteredClient,
    isLoadingAddRegisteredClient,
    addRegisteredClientResponse,
  ] = useAddRegisteredClient();

  const [removeRegisteredClient, isLoadingRemoveRegisteredClient] =
    useRemoveRegisteredClient();

  const [updateRegisteredClient, isLoadingUpdateRegisteredClient] =
    useUpdateRegisteredClient();

  const [
    updateRegisteredClientSecret,
    isUpdateRegisteredClientSecret,
    updateRegisteredClientSecretResponse,
  ] = useUpdateRegisteredClientSecret();

  const [partner, isLoadingPartner] = usePartner({
    orgId: id,
    locale: Locale.sv_SE,
  });

  /*
   * Edit Callback Url
   * */

  const [isEditCallbackUrlModalOpen, setIsEditCallbackUrlModalOpen] =
    useState(false);
  const [selectedClientId, setSelectedClientIdId] = useState<string>('');
  const [selectedUrl, setSelectedUrl] = useState(
    getCallbackUrl(selectedClientId)
  );

  function getCallbackUrl(clientId): string {
    const ret = partner?.registeredClients.find(
      (client) => client.clientId === clientId
    )?.callbackUrl;
    return ret ? ret : '';
  }

  const showEditCallbackUrlModal = (clientId: string) => {
    setSelectedClientIdId(clientId);
    setIsEditCallbackUrlModalOpen(true);
  };

  const handleEditCallbackUrlOk = () => {
    updateRegisteredClient({
      registeredClientId: selectedClientId,
      orgId: id,
      requesterLocale: Locale.sv_SE,
      callbackUrl: selectedUrl,
    });
    setIsEditCallbackUrlModalOpen(false);
  };

  const handleEditCallbackUrlCancel = () => {
    setIsEditCallbackUrlModalOpen(false);
  };

  /*
   * Add Registered Client
   * */
  const [isAddRegisteredClientModalOpen, setIsAddRegisteredClientModalOpen] =
    useState(false);

  const [newClientName, setNewClientName] = useState(
    getCallbackUrl(selectedClientId)
  );
  const [newCallbackUrl, setNewCallbackUrl] = useState(
    getCallbackUrl(selectedClientId)
  );

  const showNewRegisteredClientModal = () => {
    setIsAddRegisteredClientModalOpen(true);
  };

  const handleNewRegisteredClientOk = () => {
    addRegisteredClient({
      registeredClientId: newClientName,
      orgId: id,
      requesterLocale: Locale.sv_SE,
      callbackUrl: newCallbackUrl,
    });

    setIsAddRegisteredClientModalOpen(false);
  };

  const handleNewRegisteredClientCancel = () => {
    setIsAddRegisteredClientModalOpen(false);
  };

  /*
   * Delete Registered Client
   * */
  const [
    isDeleteRegisteredClientModalOpen,
    setIsDeleteRegisteredClientModalOpen,
  ] = useState(false);

  const showDeleteRegisteredClientModal = (clientId: string) => {
    setSelectedClientIdId(clientId);
    setIsDeleteRegisteredClientModalOpen(true);
  };

  const handleDeleteRegisteredClientCancel = () => {
    setIsDeleteRegisteredClientModalOpen(false);
  };

  const handleDeleteRegisteredClientOk = () => {
    removeRegisteredClient({
      registeredClientId: selectedClientId,
      orgId: id,
      requesterLocale: Locale.sv_SE,
    });
    setIsDeleteRegisteredClientModalOpen(false);
  };

  /*
   * Show Client Secret Dialog
   * */
  const [isShowClientSecretModalOpen, setIsShowClientSecretModalOpen] =
    useState(false);

  const [clientSecret, setClientSecret] = useState<string>();

  useEffect(() => {
    setClientSecret(addRegisteredClientResponse?.clientSecret);
  }, [addRegisteredClientResponse]);

  useEffect(() => {
    if (clientSecret) {
      setIsShowClientSecretModalOpen(true);
    }
  }, [clientSecret]);

  const handleShowClientSecretOk = () => {
    setIsShowClientSecretModalOpen(false);
    setClientSecret(undefined);
  };

  /*
   * Reset client secret
   * */
  const [isResetClientSecretModalOpen, setResetClientSecretModalOpen] =
    useState(false);

  const handleResetClientSecretOk = () => {
    updateRegisteredClientSecret({
      registeredClientId: selectedClientId,
      orgId: id,
      requesterLocale: Locale.sv_SE,
    });
    setResetClientSecretModalOpen(false);
  };

  useEffect(() => {
    setClientSecret(updateRegisteredClientSecretResponse);
  }, [updateRegisteredClientSecretResponse]);

  const handleResetClientSecretCancel = () => {
    setResetClientSecretModalOpen(false);
  };

  const showResetClientSecretModal = (clientId: string) => {
    setSelectedClientIdId(clientId);
    setResetClientSecretModalOpen(true);
  };

  const columns = [
    {
      title: 'Client Id',
      dataIndex: 'clientId',
      key: 'clientId',
    },
    {
      title: 'Callback Url',
      dataIndex: 'callbackUrl',
      key: 'callbackUrl',
    },
    {
      key: 'editCallbackUrl',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            htmlType="button"
            onClick={() => showEditCallbackUrlModal(record.clientId)}
          >
            Edit Callback Url
          </Button>
        </Space>
      ),
    },
    {
      key: 'resetClientSecret',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            htmlType="button"
            onClick={() => showResetClientSecretModal(record.clientId)}
          >
            Reset Client Secret
          </Button>
        </Space>
      ),
    },
    {
      key: 'deleteRegisteredClient',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            htmlType="button"
            onClick={() => showDeleteRegisteredClientModal(record.clientId)}
          >
            Delete Registered Client
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <h2>{'Edit Registered Clients for ' + partner?.partnerName}</h2>
      <Table
        dataSource={partner?.registeredClients?.map((client) => ({
          ...client,
          key: client.clientId,
        }))}
        columns={columns}
        loading={isLoadingPartner}
      />

      <Modal
        title="Edit Callback Url"
        open={isEditCallbackUrlModalOpen}
        footer={false}
        destroyOnClose={true}
        onCancel={handleEditCallbackUrlCancel}
      >
        <Input
          defaultValue={getCallbackUrl(selectedClientId)}
          onChange={(e) => setSelectedUrl(e.target.value)}
        />
        <Space>
          <Button type="default" onClick={handleEditCallbackUrlCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleEditCallbackUrlOk}
            loading={isLoadingUpdateRegisteredClient}
          >
            Submit
          </Button>
        </Space>
      </Modal>

      <Modal
        title={'Confirm delete registered client ' + selectedClientId}
        open={isDeleteRegisteredClientModalOpen}
        onCancel={handleDeleteRegisteredClientCancel}
        onOk={handleDeleteRegisteredClientOk}
        confirmLoading={isLoadingRemoveRegisteredClient}
        destroyOnClose={true}
      />

      <Modal
        title="Add Registered Client"
        open={isAddRegisteredClientModalOpen}
        footer={false}
        destroyOnClose={true}
        onCancel={handleNewRegisteredClientCancel}
      >
        <Input
          placeholder="Name"
          onChange={(e) => setNewClientName(e.target.value)}
        />
        <Input
          placeholder="http://clientCallbackUrl"
          onChange={(e) => setNewCallbackUrl(e.target.value)}
        />
        <Space>
          <Button type="default" onClick={handleNewRegisteredClientCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleNewRegisteredClientOk}
            loading={isLoadingAddRegisteredClient}
          >
            Submit
          </Button>
        </Space>
      </Modal>

      <Modal
        title="Save this client secret, it will only be showed once!"
        open={isShowClientSecretModalOpen}
        onOk={handleShowClientSecretOk}
        destroyOnClose={true}
        footer={(_, { OkBtn }) => (
          <>
            <OkBtn />
          </>
        )}
      >
        <h4>{clientSecret}</h4>
      </Modal>

      <Modal
        title={'Confirm reset client secret for ' + selectedClientId}
        open={isResetClientSecretModalOpen}
        onCancel={handleResetClientSecretCancel}
        onOk={handleResetClientSecretOk}
        confirmLoading={isUpdateRegisteredClientSecret}
      />

      <Space>
        <Button
          type="primary"
          htmlType="button"
          onClick={showNewRegisteredClientModal}
        >
          Add Registered Client
        </Button>
        <Link to={'/'}>
          <Button type="primary" htmlType="button">
            Back
          </Button>
        </Link>
      </Space>
    </>
  );
};

export default RegisteredClientsFormView;
