import axios, { AxiosResponse } from 'axios';
import { ENDPOINT_BASE } from 'constants/index';

const BANKID_PATH = 'bankid';
const COLLECT_PATH = 'collect';
const CANCEL_PATH = 'cancel';
const AUTH_PATH = 'auth';
const baseUrl = `${ENDPOINT_BASE}${BANKID_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

export enum BankIdAuthenticationStatus {
  UNHANDLED = 'unhandled',
  PENDING = 'pending',
  COMPLETED = 'complete',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  UNKNOWN = 'unknown',
}

export type CollectResponse = {
  hintCode: string;
  status: BankIdAuthenticationStatus;
};

export type CancelResponse = {
  status: BankIdAuthenticationStatus;
};

export type AuthenticateRequest = {
  pno: string;
  tokenStartRequired: boolean;
};

export type AuthenticateResponse = {
  orderRef: string;
  autoStartToken: string;
};

export async function authenticate(
  request: AuthenticateRequest | undefined | null
): Promise<AxiosResponse<AuthenticateResponse>> {
  if (!request) {
    throw new Error('AuthenticateRequest is missing on authenticate api call');
  }
  return client.post<AuthenticateResponse>(`/${AUTH_PATH}`, request);
}

export async function collect(
  orderRef: string | undefined | null
): Promise<AxiosResponse<CollectResponse>> {
  if (!orderRef) {
    throw new Error('orderRef is missing on collect api call');
  }

  const response = await client.get<CollectResponse>(
    `/${COLLECT_PATH}/${orderRef}`
  );

  return response;
}

export async function cancel(
  orderRef: string | undefined | null
): Promise<CancelResponse> {
  if (!orderRef) {
    throw new Error('orderRef is missing on cancel api call');
  }
  const response = await client.get<CancelResponse>(
    `/${CANCEL_PATH}/${orderRef}`
  );

  return response.data;
}
