import {
  authenticate,
  AuthenticateRequest,
  AuthenticateResponse,
  BankIdAuthenticationStatus,
  collect,
  cancel,
  CancelResponse,
} from 'api/bankIdClient';
import { authenticationStatus } from 'api/authenticationClient';
import { AxiosResponse } from 'axios';
import { message } from 'antd';

import {
  QueryClient,
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

const COLLECT_REFETCH_INTERVAL_MILLIS = 5000;

function removeQueriesFromCollect(queryClient: QueryClient): void {
  queryClient?.removeQueries({
    queryKey: [collect.name],
  });
}

export function isAuthenticating(
  collectStatus: 'error' | 'success' | 'pending',
  bankIdResponse: AuthenticateResponse | undefined,
  collectDataStatus: BankIdAuthenticationStatus | 'timeout' | undefined,
  isLoadingBankId: boolean
): boolean {
  const collectIsStarting: boolean =
    collectStatus === 'pending' && bankIdResponse != null;
  const collectionIsPending: boolean =
    collectDataStatus === BankIdAuthenticationStatus.PENDING &&
    bankIdResponse != null;
  return isLoadingBankId || collectIsStarting || collectionIsPending;
}

export function useCancelAuthentication(): [
  UseMutateFunction<CancelResponse, Error, string | null | undefined, unknown>,
  boolean
] {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: [cancel.name],
    mutationFn: cancel,
    onSuccess: () => {
      message.success('Cancelled authentication');
      removeQueriesFromCollect(queryClient);
    },
    onError: () => {
      message.error('Cancelling of authentication failed!');
      removeQueriesFromCollect(queryClient);
    },
  });
  return [mutation.mutate, mutation.isPending];
}

export function useBankidAuth(
  callback?:
    | ((
        data: AxiosResponse<AuthenticateResponse>,
        variables: AuthenticateRequest | null | undefined,
        context: unknown
      ) => unknown)
    | undefined
): [
  UseMutateFunction<
    AxiosResponse<AuthenticateResponse>,
    unknown,
    AuthenticateRequest | null | undefined,
    unknown
  >,
  boolean,
  AuthenticateResponse | undefined,
  () => void
] {
  const mutation = useMutation({
    mutationKey: [authenticate.name],
    mutationFn: authenticate,
    onSuccess: callback,
    onError: () => {
      message.error('Authentication failed!');
    },
  });

  return [
    mutation.mutate,
    mutation.isPending,
    mutation.data?.data,
    mutation.reset,
  ];
}
export function useBankidCollect(
  ref: string | undefined,
  onAuthenticationCompleted: () => void
): [
  'error' | 'success' | 'pending',
  boolean,
  BankIdAuthenticationStatus | undefined
] {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [collect.name],
    queryFn: async () => {
      try {
        const resp = await collect(ref);

        if (resp.data?.status !== BankIdAuthenticationStatus.PENDING) {
          onAuthenticationCompleted();
          await queryClient.invalidateQueries({
            queryKey: [authenticationStatus.name],
          });
        }

        return resp;
      } catch (e) {
        onAuthenticationCompleted();
        removeQueriesFromCollect(queryClient);
      }
    },
    initialData: undefined,
    retry: false,
    enabled: !!ref,
    refetchInterval: COLLECT_REFETCH_INTERVAL_MILLIS,
  });

  return [query.status, query.isRefetching, query.data?.data.status];
}
