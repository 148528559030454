import React, { useCallback } from 'react';
//import './index.css';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useCreatePartner } from 'hooks/withPartner';
import { Locale } from '../../../types/partnerTypes';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 5 },
  },
};

type FieldType = {
  partnerName?: string;
  orgId?: string;
};

const numberMatcher = new RegExp(/^[0-9]+$/); //TODO: Make numberMatcher a pnr matcher lib

const AddPartnersModal: React.FC<{ closeForm: () => void }> = ({
  closeForm,
}) => {
  const [postCreatePartner, isLoadingCreatePartner] = useCreatePartner();

  const handleCreatePartner = useCallback(
    (createPartnerRequest: {
      partnerName: string;
      orgId: string;
      authorizedUsers: string[];
    }) => {
      try {
        postCreatePartner({
          partnerName: createPartnerRequest.partnerName,
          orgId: createPartnerRequest.orgId,
          authorizedUsers: createPartnerRequest.authorizedUsers,
          requesterLocale: Locale.sv_SE,
        });
        closeForm();
      } catch (e) {
        alert('Error: ' + e);
      }
    },
    [postCreatePartner, closeForm]
  );

  return (
    <Form
      name="dynamic_form_item"
      {...formItemLayoutWithOutLabel}
      onFinish={handleCreatePartner}
      style={{ maxWidth: 600 }}
    >
      <Form.Item>
        <span className="ant-form-text">Add new Partner</span>
      </Form.Item>
      <Form.Item<FieldType>
        {...formItemLayout}
        label="Company Name"
        name="partnerName"
        rules={[{ required: true, message: 'Please input your company name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<FieldType>
        {...formItemLayout}
        label="Organization Id"
        name="orgId"
        rules={[
          {
            required: true,
            message: 'Please input your company identification number!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.List name="authorizedUsers">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? 'Authorized Users' : ''}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      validator: async (_, value) => {
                        if (
                          !value ||
                          value.length != 12 ||
                          !numberMatcher.test(value)
                        ) {
                          return Promise.reject(
                            new Error(
                              'Input a valid 12 number length personal number'
                            )
                          );
                        }
                      },
                    },
                  ]}
                  noStyle
                >
                  <Input
                    placeholder="a valid personal number"
                    style={{ width: '60%' }}
                  />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                Add authorized user
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoadingCreatePartner}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddPartnersModal;
