import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spin } from 'antd';

import Layout from 'components/Layout';
import AccountDetailsView from 'views/AccountDetails';
import LoginView from 'views/Login';
import PartnersFormView from 'views/PartnersForm';

import { useAuthentication } from 'hooks/withAuthentication';
import RegisteredClientsFormView from 'views/RegisteredClientsForm';

const App: React.FC = () => {
  const [isAuthenticated, isLoading] = useAuthentication();

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }

  return (
    <Layout isAuthenticated={isAuthenticated}>
      <Routes>
        {isAuthenticated && (
          <>
            <Route path="/" element={<PartnersFormView />} />
            <Route
              path="/clients/:id"
              element={<RegisteredClientsFormView />}
            />
            <Route path="/account" element={<AccountDetailsView />} />
          </>
        )}
        {!isAuthenticated && (
          <>
            <Route path="*" element={<LoginView />} />
          </>
        )}
      </Routes>
    </Layout>
  );
};

export default App;
