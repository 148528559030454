import {
  createPartner,
  getPartner,
  getPartners,
  updatePartnerAuthorizedUsers,
} from 'api/partnersClient';

import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import {
  CreatePartnerRequest,
  CreatePartnerResponse,
  GetPartnerInfoResponse,
  Locale,
  UpdatePartnerRequest,
} from '../types/partnerTypes';

export const usePartner = ({
  orgId,
  locale = Locale.sv_SE,
}: {
  orgId: string;
  locale?: Locale;
}): [GetPartnerInfoResponse | undefined, boolean, number | undefined] => {
  const query = useQuery({
    queryKey: [getPartner.name],
    queryFn: () => getPartner({ orgId, requesterLocale: locale }),
    initialData: undefined,
  });

  return [query.data?.data, query.isLoading, query.data?.status];
};

export const usePartners = ({
  locale = Locale.sv_SE,
}: {
  locale?: Locale;
}): [
  Array<GetPartnerInfoResponse> | undefined,
  boolean,
  number | undefined
] => {
  const query = useQuery({
    queryKey: [getPartners.name],
    queryFn: () => getPartners(locale),
    initialData: undefined,
  });

  return [query.data?.data, query.isLoading, query.data?.status];
};

export const useCreatePartner = (): [
  UseMutateFunction<
    AxiosResponse<CreatePartnerResponse>,
    Error,
    CreatePartnerRequest | null | undefined,
    unknown
  >,
  boolean
] => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: [createPartner.name],
    mutationFn: createPartner,
    onError: () => {
      message.error('Create partner failed!');
    },
    onSuccess: () => {
      message.success('Created Partner');
      queryClient.invalidateQueries({
        queryKey: [getPartners.name],
      });
    },
  });

  return [mutation.mutate, mutation.isPending];
};

export const useUpdatePartnerAuthorizedUsers = (): [
  UseMutateFunction<
    AxiosResponse<GetPartnerInfoResponse>,
    Error,
    UpdatePartnerRequest | null | undefined,
    unknown
  >,
  boolean
] => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: [updatePartnerAuthorizedUsers.name],
    mutationFn: updatePartnerAuthorizedUsers,
    onError: () => {
      message.error('Update authorized users failed!');
    },
    onSuccess: () => {
      message.success('Updated authorized users');
      queryClient.invalidateQueries({
        queryKey: [getPartners.name],
      });
    },
  });

  return [mutation.mutate, mutation.isPending];
};
