import React, { useCallback, useState } from 'react';
import axios from 'axios';
import SupportSharpIcon from '@mui/icons-material/SupportSharp';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

import Footer from './Footer';
import Header from './Header';
import Sidebar, { LinkItem } from './Sidebar';
import classNames from 'classnames';

const links: LinkItem[] = [
  {
    path: '/',
    icon: ConfirmationNumberIcon,
    label: 'Partners',
  },
];

const externalLink: LinkItem = {
  path: 'http://github.com/',
  icon: SupportSharpIcon,
  label: 'Documentation',
};
type ChildProp = {
  children: React.ReactNode;
  isAuthenticated: boolean;
};

const Layout: React.FC<ChildProp> = ({ children, isAuthenticated }) => {
  const handleLogOut = useCallback((e) => {
    e.preventDefault();
    document.cookie = 'JSESSIONID=';
    sessionStorage.clear();
    window.location.reload();
  }, []);

  const [isOpened, setIsOpened] = useState(false);

  const handleToggleIsOpened = useCallback(() => {
    setIsOpened((value) => !value);
  }, [setIsOpened]);

  return isAuthenticated ? (
    <>
      <Header handleToggleIsOpened={handleToggleIsOpened} isOpened={isOpened} />
      <div className="flex overflow-hidden bg-white pt-16 w-full">
        <Sidebar
          isAuthenticated={isAuthenticated}
          links={isAuthenticated ? links : undefined}
          externalLink={externalLink}
          handleLogOut={handleLogOut}
          isOpened={isOpened}
        />
        <div
          className={classNames(
            'bg-gray-900',
            'opacity-50',
            'fixed',
            'inset-0',
            'z-10',
            'lg:hidden',
            { hidden: !isOpened }
          )}
          id="sidebarBackdrop"
          onClick={handleToggleIsOpened}
        ></div>

        <div
          id="main-content"
          className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
        >
          <main>{children}</main>
          <Footer />
        </div>
      </div>
    </>
  ) : (
    <div className="w-full flex flex-col">
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
