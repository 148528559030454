import { getPartner } from 'api/partnersClient';

import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import {
  addRegisteredClient,
  removeRegisteredClient,
  updateRegisteredClient,
  updateRegisteredClientSecret,
} from 'api/registeredClientsClient';
import {
  CreateRegisteredClientRequest,
  CreateRegisteredClientResponse,
  DeleteRegisteredClientRequest,
  UpdateRegisteredClientSecretRequest,
} from '../types/registeredClientsTypes';

export const useAddRegisteredClient = (): [
  UseMutateFunction<
    AxiosResponse<CreateRegisteredClientResponse>,
    Error,
    CreateRegisteredClientRequest | null | undefined,
    unknown
  >,
  boolean,
  CreateRegisteredClientResponse | undefined
] => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: [addRegisteredClient.name],
    mutationFn: addRegisteredClient,
    onError: () => {
      message.error('Add registered client failed!');
    },
    onSuccess: () => {
      message.success('Added registered client');
      queryClient.invalidateQueries({
        queryKey: [getPartner.name],
      });
    },
  });

  return [mutation.mutate, mutation.isPending, mutation.data?.data];
};

export const useRemoveRegisteredClient = (): [
  UseMutateFunction<
    AxiosResponse,
    Error,
    DeleteRegisteredClientRequest | null | undefined,
    unknown
  >,
  boolean
] => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: [removeRegisteredClient.name],
    mutationFn: removeRegisteredClient,
    onError: () => {
      message.error('Remove registered client failed!');
    },
    onSuccess: () => {
      message.success('Removed registered client');
      queryClient.invalidateQueries({
        queryKey: [getPartner.name],
      });
    },
  });

  return [mutation.mutate, mutation.isPending];
};

export const useUpdateRegisteredClient = (): [
  UseMutateFunction<
    AxiosResponse,
    Error,
    CreateRegisteredClientRequest | null | undefined,
    unknown
  >,
  boolean
] => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: [updateRegisteredClient.name],
    mutationFn: updateRegisteredClient,
    onError: () => {
      message.error('Update registered client failed!');
    },
    onSuccess: () => {
      message.success('Updated registered client');
      queryClient.invalidateQueries({
        queryKey: [getPartner.name],
      });
    },
  });

  return [mutation.mutate, mutation.isPending];
};

export const useUpdateRegisteredClientSecret = (): [
  UseMutateFunction<
    AxiosResponse<string>,
    Error,
    UpdateRegisteredClientSecretRequest | null | undefined,
    unknown
  >,
  boolean,
  string | undefined
] => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: [updateRegisteredClientSecret.name],
    mutationFn: updateRegisteredClientSecret,
    onError: () => {
      message.error('Update registered client secret failed!');
    },
    onSuccess: () => {
      message.success('Updated registered client secret');
      queryClient.invalidateQueries({
        queryKey: [getPartner.name],
      });
    },
  });

  return [mutation.mutate, mutation.isPending, mutation.data?.data];
};
