import React, { useCallback, useState } from 'react';
import { Button, Modal, Space, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { usePartners } from 'hooks/withPartner';
import { PlusOutlined } from '@ant-design/icons';
import AddPartnersModal from 'views/PartnersForm/PartnerModal/AddPartnersModal';
import { GetPartnerInfoResponse, Locale } from '../../types/partnerTypes';
import EditUsersModal from 'views/PartnersForm/PartnerModal/EditUsersModal';

function getAuthorizedUsersForSelectedPartner(
  partners: GetPartnerInfoResponse[] | undefined,
  selectedPartnerOrgId: string
): string[] | undefined {
  return partners
    ? partners.find((partner) => selectedPartnerOrgId === partner.orgId)
        ?.authorizedUsers
    : undefined;
}

function getSelectedPartnerName(
  partners: GetPartnerInfoResponse[] | undefined,
  selectedPartnerOrgId: string
): string | undefined {
  return partners
    ? partners.find((partner) => selectedPartnerOrgId === partner.orgId)
        ?.partnerName
    : undefined;
}

const PartnersFormView: React.FC = () => {
  const [isCreatePartnerModalVisible, setIsCreatePartnerModalVisible] =
    useState(false);
  const [isEditUsersModalVisible, setIsEditUsersModalVisible] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<string>('');

  const [partners, isLoadingPartners] = usePartners({
    locale: Locale.sv_SE,
  });

  const showEditUsersModal = useCallback((orgId: string) => {
    setSelectedPartner(orgId);
    setIsEditUsersModalVisible(true);
  }, []);

  const closeEditUsersModal = useCallback(() => {
    setIsEditUsersModalVisible(false);
  }, []);

  const showCreatePartnerModal = useCallback(() => {
    setIsCreatePartnerModalVisible(true);
  }, []);

  const closeCreatePartnerModal = useCallback(() => {
    setIsCreatePartnerModalVisible(false);
  }, []);

  const columns = [
    {
      title: 'Partner Name',
      dataIndex: 'partnerName',
      key: 'partnerName',
    },
    {
      title: 'Organization Id',
      dataIndex: 'orgId',
      key: 'orgId',
    },
    {
      title: 'Approved',
      dataIndex: 'approved',
      key: 'approved',
      render: (_, { approved }) => (
        <Tag color={approved === false ? 'red' : 'green'} key={approved}>
          {approved === false ? 'No' : 'Yes'}
        </Tag>
      ),
    },
    {
      key: 'editUsers',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            htmlType="button"
            onClick={() => showEditUsersModal(record.orgId)}
          >
            Edit Users
          </Button>
        </Space>
      ),
    },
    {
      key: 'editRegisteredClients',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/clients/${record.orgId}`}>
            <Button type="primary" disabled={!record.approved}>
              Edit Registered Clients
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={partners?.map((partner) => ({
          ...partner,
          key: partner.orgId,
        }))}
        columns={columns}
        loading={isLoadingPartners}
      />

      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showCreatePartnerModal}
      >
        Add new partner
      </Button>
      <Modal
        title="Create new Partner"
        open={isCreatePartnerModalVisible}
        onCancel={closeCreatePartnerModal}
        width="800px"
        footer={null}
        destroyOnClose
      >
        <AddPartnersModal closeForm={closeCreatePartnerModal} />
      </Modal>
      <Modal
        title={
          'Edit authorized users for ' +
          getSelectedPartnerName(partners, selectedPartner)
        }
        open={isEditUsersModalVisible}
        onCancel={closeEditUsersModal}
        destroyOnClose
        footer={null}
      >
        <EditUsersModal
          closeForm={closeEditUsersModal}
          orgId={selectedPartner}
          initialData={getAuthorizedUsersForSelectedPartner(
            partners,
            selectedPartner
          )}
        />
      </Modal>
    </>
  );
};

export default PartnersFormView;
