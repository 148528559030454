import axios, { AxiosResponse } from 'axios';
import { ENDPOINT_BASE } from 'constants/index';
import {
  CreateRegisteredClientRequest,
  CreateRegisteredClientResponse,
  DeleteRegisteredClientRequest,
  UpdateRegisteredClientSecretRequest,
} from '../types/registeredClientsTypes';

const PARTNERS_PATH = 'clients';
const baseUrl = `${ENDPOINT_BASE}${PARTNERS_PATH}`;

const client = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

export async function addRegisteredClient(
  request: CreateRegisteredClientRequest | undefined | null
): Promise<AxiosResponse<CreateRegisteredClientResponse>> {
  if (!request) {
    throw new Error('GetPartnersRequest is missing on getPartner api call');
  }
  console.log('addRegisteredClient: ' + JSON.stringify(request));
  return client.post<CreateRegisteredClientResponse>(``, request);
}

export async function removeRegisteredClient(
  request: DeleteRegisteredClientRequest | undefined | null
): Promise<AxiosResponse> {
  if (!request) {
    throw new Error('GetPartnersRequest is missing on getPartner api call');
  }
  console.log('removeRegisteredClient: ' + JSON.stringify(request));
  return client.delete(
    `/${request.orgId}/${request.registeredClientId}/${request.requesterLocale}`
  );
}

export async function updateRegisteredClient(
  request: CreateRegisteredClientRequest | undefined | null
): Promise<AxiosResponse> {
  if (!request) {
    throw new Error('GetPartnersRequest is missing on getPartner api call');
  }
  console.log('updateRegisteredClient: ' + JSON.stringify(request));
  return client.put(``, request);
}

export async function updateRegisteredClientSecret(
  request: UpdateRegisteredClientSecretRequest | undefined | null
): Promise<AxiosResponse<string>> {
  if (!request) {
    throw new Error('GetPartnersRequest is missing on getPartner api call');
  }
  console.log('updateRegisteredClientSecret: ' + JSON.stringify(request));
  return client.put<string>('/secret', request);
}
