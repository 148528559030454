import React from 'react';

const AccountView: React.FC = () => {
  return (
    <div className="card">
      <h1 className="mb-5 py-5">Account details</h1>
    </div>
  );
};

export default AccountView;
