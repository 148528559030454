export enum Locale {
  sv_SE = 'sv_SE',
}
export type RegisteredClient = {
  clientId: string;
  callbackUrl: string;
};

export type Partner = GetPartnerInfoResponse;

export type GetPartnerRequest = PartnerRequest;

export interface PartnerRequest {
  orgId: string;
  requesterLocale: Locale;
}

export interface CreatePartnerResponse {
  partnerName: string;
  orgId: string;
  authorizedUsers: string[];
}

export interface GetPartnerInfoResponse extends CreatePartnerResponse {
  approved: boolean;
  created: string;
  registeredClients: RegisteredClient[];
}

export interface CreatePartnerRequest extends PartnerRequest {
  partnerName: string;
  authorizedUsers: string[];
}

export interface UpdatePartnerRequest extends PartnerRequest {
  authorizedUsers: string[];
}
