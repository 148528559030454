import React from 'react';
import { render } from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import './index.css';

// Create a client
const queryClient = new QueryClient();

const isDevServer =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const container = document.getElementById('root');
render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        {isDevServer && <ReactQueryDevtools initialIsOpen={false} />}
        <App />
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
