import { authenticationStatus } from '../api/authenticationClient';

import { useQuery } from '@tanstack/react-query';

export const useAuthentication = (): [boolean, boolean] => {
  const query = useQuery({
    queryKey: [authenticationStatus.name],
    queryFn: authenticationStatus,
    initialData: undefined,
    retry: false,
  });

  return [!!query.data?.data.authenticated, query.isLoading];
};
